import React, {Component} from 'react'
import { graphql } from 'gatsby'
import { Document, Page } from 'react-pdf';



import Layout from '../components/layout'

export const query = graphql`
    query pdfTemplateQuery($id: String!) {
        pdf: sanityPdfPage(id: { eq: $id }) {  
            _id
            title
            document {
                asset {
                    url
                }
            }
            slug {
                current
            } 
        }
    }    
`



class pdfPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
          numPages: null,
          pageWidth: null,
          pageNumber: 1, // Start with the first page
        };
        this.containerRef = React.createRef();
      }


      componentDidMount() {
        this.setPageWidth();
        window.addEventListener('resize', this.setPageWidth);
      }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.setPageWidth);
      }

      onDocumentLoadSuccess = ({ numPages }) => {
        this.setPageWidth();
        this.setState({ numPages });
      };

      setPageWidth = () => {
        if (this.containerRef.current) {
          this.setState({ pageWidth: this.containerRef.current.clientWidth });
        }
      };

      renderPages = () => {
        const { numPages } = this.state;
        let pages = [];
        for (let i = 1; i <= numPages; i++) {
          pages.push(<Page key={i} pageNumber={i} width={this.state.pageWidth} style={{ width: '90vw' }}/>);
        }
        return pages;
      };

    
    render() {
        const { pageNumber, numPages } = this.state;
        return (
            <div ref={this.containerRef}>
                <embed src={this.props.data.pdf.document.asset.url} className="h-full w-full h-screen hidden xl:block"/>

                   
                    

                <Document
                    file={this.props.data.pdf.document.asset.url}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    className="xl:hidden block"
                >
                    {this.renderPages()}
                </Document>
               
            </div>
        )
    } 
}

export default pdfPage